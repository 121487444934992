<template>
  <div class="login">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
      <span class="title">河湖健康管理评估平台</span>
      <el-form-item prop="username">
        <el-input
          v-model="loginForm.username"
          type="text"
          auto-complete="off"
          placeholder="请输入账号或名称"
          :clearable="true"
        >
          <!-- <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" /> -->
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="loginForm.password"
          type="password"
          auto-complete="off"
          placeholder="请输入密码"
          :clearable="true"
          @keyup.enter.native="handleLogin"
        >
          <!-- <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" /> -->
        </el-input>
      </el-form-item>
      <el-form-item style="width: 100%">
        <el-button
          :loading="loading"
          size="medium"
          type="primary"
          style="width: 100%"
          @click.native.prevent="handleLogin"
        >
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!--  底部  -->
    <div class="el-login-footer">
      <span>Copyright © 上海协济科技有限公司</span>
    </div>
  </div>
</template>

<script>
  import { Login, User, ProvinceLogin } from '@/api/login/login'
  import Cookies from 'js-cookie'
  import { setProvinceToken, setToken, removeToken } from '@/utils/auth'

  export default {
    name: 'Login',
    data() {
      return {
        codeUrl: '',
        cookiePassword: '',
        // 验证码组件
        // identifyCodes: 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz0123456789',
        // identifyCode: '',
        loginForm: {
          username: '',
          password: '',
          rememberMe: false
          // vertify_code: "",
          // uuid: ""
        },
        loginRules: {
          username: [{ required: false, trigger: 'blur', message: '用户名不能为空' }],
          password: [{ required: false, trigger: 'blur', message: '密码不能为空' }]
          // code: [{ required: true, trigger: "change", message: "验证码不能为空" }]
          // 验证码输入是否正确
          // vertify_code: [
          //   {
          //     required: true,
          //     message: '请输入验证码',
          //     trigger: 'blur'
          //   },
          //   { required: true, validator: this.validateCode, change: 'blur' }]
        },
        loading: false,
        redirect: undefined
      }
    },
    // components: { VertifyCode }, // 注册组件
    watch: {
      $route: {
        handler: function (route) {
          this.redirect = route.query && route.query.redirect
        },
        immediate: true
      }
    },
    created() {
      // this.getCode();
      this.getCookie()
    },
    methods: {
      // getCode() {
      //   getCodeImg().then(res => {
      //     this.codeUrl = "data:image/gif;base64," + res.img;
      //     this.loginForm.uuid = res.uuid;
      //   });
      // },
      getCookie() {
        const username = Cookies.get('username')
        const password = Cookies.get('password')
        const rememberMe = Cookies.get('rememberMe')
        this.loginForm = {
          username: username === undefined ? this.loginForm.username : username,
          password: password === undefined ? this.loginForm.password : password,
          rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
        }
      },
      handleLogin() {
        this.$refs.loginForm.validate((valid) => {
          if (valid) {
            this.loading = true
            if (this.loginForm.rememberMe) {
              Cookies.set('username', this.loginForm.username, { expires: 30 })
              Cookies.set('password', this.loginForm.password, { expires: 30 })
              Cookies.set('rememberMe', this.loginForm.rememberMe, {
                expires: 30
              })
            } else {
              Cookies.remove('username')
              Cookies.remove('password')
              Cookies.remove('rememberMe')
            }
            let params = {
              username: this.loginForm.username.trim(),
              password: this.loginForm.password
            }
            Login.loginIn(params)
              .then((res) => {
                console.log('login', res)
                if (res.data.code !== 0) {
                  this.loading = false
                } else {
                  setToken(res.data.data['PRIVATE-TOKEN'])

                  User.getInfo().then((res) => {
                    console.log('<==  ==>', res)
                    if (res.data.data.authtoken) {
                      // 通过吴江系统返回的账户信息中的映射token信息，请求省级平台获取省级平台token，获取健康在线信息数据
                      ProvinceLogin.loginIn(res.data.data.authtoken).then((res) => {
                        console.log(res, 11)
                        setProvinceToken(res.data.data)
                      })
                      // .catch((err) => {
                      //   throw new Error(err)
                      // })
                    }

                    if (res.data.data.type === 'leader') {
                      this.$router.push({ path: '/online/map' })
                    } else {
                      this.$router.push({ path: this.redirect || '/createPro' })
                    }
                  })
                }
              })
              .catch((error) => {
                this.loading = false
                this.$message({
                  message: error
                })
                throw new Error(error)
              })
          }
        })
      }
    }
  }
</script>

<style rel="stylesheet/less" lang="less" scope>
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('../assets/login-background.png');
    background-size: cover;
  }

  .title {
    // margin: 0px auto 30px auto;
    // text-align: center;
    // color: #707070;

    // font-family: title-font;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height, or 119% */

    /* 字体/1/特级文字 */

    color: #151a30;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 24px 0px;
  }

  .login-form {
    // border-radius: 6px;
    // background: #ffffff;
    // width: 300px;
    // padding: 25px 25px 5px 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;

    position: absolute;
    width: 20%;
    max-width: 260px;
    height: 280px;
    left: 75%;
    top: 300px;

    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(133.125px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 4px;

    .el-input {
      height: 38px;
      width: 260px;
      input {
        height: 38px;
      }
    }

    .input-icon {
      height: 39px;
      width: 14px;
      margin-left: 2px;
    }
  }

  .login-tip {
    font-size: 13px;
    text-align: center;
    color: #bfbfbf;
  }

  .login-code {
    width: 33%;
    height: 38px;
    float: right;

    img {
      cursor: pointer;
      vertical-align: middle;
    }
  }

  .el-login-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .login-code {
    cursor: pointer;
    margin: 0;
  }
</style>
